<template>
  <b-container data-cy="login" class="login">
    <h1>Sign in</h1>
    <b-form class="fields form-group" @keyup.enter="login">
      <b-form-group :label="'Email'">
        <b-form-input
          id="email"
          v-model="email"
          :placeholder="'Email address'"
          aria-describedby="input-live-help input-live-feedback"
          autocomplete="username email"
          :state="(validEmail || !errors) ? true : false"
          :errors="errorsFor"
          trim
        />
      </b-form-group>
      <b-form-group :label="'Password'">
        <b-form-input
          id="password"
          v-model="password"
          :type="passwordInputType"
          :placeholder="'Confirm Password:'"
          autocomplete="on"
          aria-describedby="input-live-help input-live-feedback"
          :state="(validPassword || !errors) ? true : false"
          :errors="errorsFor"
          trim
        />
      </b-form-group>
      <b-form-checkbox
        id="showPasswordCheckbox"
        v-model="showPassword"
        :value="true"
        :unchecked-value="false"
      >
        Show Password
      </b-form-checkbox>
      <br>
      <router-link to="/forgot-password">
        Forgot Password
      </router-link>
    </b-form>
    <div v-if="errorsFor.length" class="invalid">
      {{ formattedError() }}
    </div>
    <b-button
      id="submit"
      class="btn btn-primary"
      :disabled="disabled"
      @click="login"
    >
      Submit
    </b-button>
  </b-container>
</template>

<script>
import { SERVER_ERROR } from '@/common/modules/errors'
import * as Cookies from 'js-cookie'

export default {
  name: 'Login',
  data() {
    return {
      showPassword: this.showPassword || false,
      email: '',
      password: '',
      errors: '',
    }
  },
  computed: {
    valid: {
      get() {
        let result = true
        for (const key in this.errors) {
          if (this.errors[key].length > 0) {
            return result
          }
        }
        return result
      },
    },
    errorsFor() {
      let result = ''
      if (this.errors) {
        result = this.errors
      }
      return result
    },
    disabled() {
      return (
        !this.validEmail() || !this.validPassword() || this.$store.state.busy
      )
    },
    passwordInputType() {
      return this.showPassword ? 'text' : 'password'
    },
    token() {
      return (
        this.$store.state.session.token !== undefined &&
        this.$store.state.session.token !== null
      )
    },
  },
  mounted() {
    if (this.token) {
      this.$router.push('/')
    }
  },
  methods: {
    formValid() {
      return this.validEmail() && this.validPassword()
    },
    validEmail() {
      return this.email && this.email.length && this.email.length > 5
    },
    validPassword() {
      return this.password && this.password.length && this.password.length > 3
    },
    async login() {
      try {
        let result = null
        if (this.$route.params['websiteId']){
          result = await this.$store.dispatch(`session/create`, {
            emailAddress: this.email,
            password: this.password,
            websiteId: this.$route.params['websiteId'],
            subdomain: null,
          })
        } else {
          const domains = window.location.hostname.split('.')
          let subdomain = domains[0]
          // this is to handle the login page at accounts.northwestregisteredagent.com
          // it also mimics the logic in Client::WebsitesController.by_subdomain in v2-api
          if (subdomain === "accounts") {
            subdomain = domains[1]
          }

          result = await this.$store.dispatch(`session/create`, {
            emailAddress: this.email,
            password: this.password,
            websiteId: null,
            subdomain,
          })
        }

        if (result.success) {
          // Set cookie to establish a session, doing this strictly on the login
          // component, could do it in session store, but no need to break
          // any other forms of logging in. This whole page is broken
          if(result.data?.token) this.setToken(result.data.token)

          const url = this.$store.state.session.redirectUrl || '/'
          await this.$router.push(url)
        } else if (result.data && result.data.error) {
          this.errors = result.data.error.message
        }
      } catch (error) {
        await this.$store.dispatch('alerts/addAlert', {
          all: [{ variant: 'danger', value: SERVER_ERROR }],
        })
      }
    },
    setToken(token) {
      const oneHour = 1/24

      const params = {
        expires: oneHour,
        sameSite: 'strict',
        domain: window.location.hostname,
      }

      Cookies.set(
        'token',
        token,
        params,
      )
    },
    formattedError() {
      if (this.errors)
        return this.errors[0].toUpperCase() + this.errors.slice(1)
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

button:disabled {
  background-color: darkgray;
  border-color: darkgray;
}

.login {
  max-width: 420px;
}

@media only screen and (max-width: 550px) {
  .login {
    margin-left: 0px;
  }
}

.invalid {
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 1em;
}
</style>
