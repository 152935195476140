var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "login", attrs: { "data-cy": "login" } },
    [
      _c("h1", [_vm._v("Sign in")]),
      _c(
        "b-form",
        {
          staticClass: "fields form-group",
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.login.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Email" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "email",
                  placeholder: "Email address",
                  "aria-describedby": "input-live-help input-live-feedback",
                  autocomplete: "username email",
                  state: _vm.validEmail || !_vm.errors ? true : false,
                  errors: _vm.errorsFor,
                  trim: "",
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Password" } },
            [
              _c("b-form-input", {
                attrs: {
                  id: "password",
                  type: _vm.passwordInputType,
                  placeholder: "Confirm Password:",
                  autocomplete: "on",
                  "aria-describedby": "input-live-help input-live-feedback",
                  state: _vm.validPassword || !_vm.errors ? true : false,
                  errors: _vm.errorsFor,
                  trim: "",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: {
                id: "showPasswordCheckbox",
                value: true,
                "unchecked-value": false,
              },
              model: {
                value: _vm.showPassword,
                callback: function ($$v) {
                  _vm.showPassword = $$v
                },
                expression: "showPassword",
              },
            },
            [_vm._v("\n      Show Password\n    ")]
          ),
          _c("br"),
          _c("router-link", { attrs: { to: "/forgot-password" } }, [
            _vm._v("\n      Forgot Password\n    "),
          ]),
        ],
        1
      ),
      _vm.errorsFor.length
        ? _c("div", { staticClass: "invalid" }, [
            _vm._v("\n    " + _vm._s(_vm.formattedError()) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "b-button",
        {
          staticClass: "btn btn-primary",
          attrs: { id: "submit", disabled: _vm.disabled },
          on: { click: _vm.login },
        },
        [_vm._v("\n    Submit\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }